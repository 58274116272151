@import '~bootstrap/scss/bootstrap.scss';

:root {
	--plenoil-blue: #002f73;
	--plenoil-blue2: #4B50B9;
	--plenoil-orange: #ff7a00;
	--plenoil-orange2: #F05A23;
	--plenoil-orange3: #f7941f;
	--plenoil-yellow: #fcd937;
	--plenoil-yellow2: #E6AA41;
	--plenoil-black: #1E282D;
	--plenoil-black-shadow: rgba(30, 40, 45, 0.4);
	--plenoil-gray: #E6E6E6;
	--plenoil-gray2: #cdcdcd;
	--plenoil-green: #007961;
	--plenoil-green2: #23B473;
	--plenoil-white: white;
	--plenoil-red: #d23627;
	--plenoil-brown: #b96e50;

	--plenoil-shadow: 0px 0px 20px var(--plenoil-black-shadow);

	--toastify-color-light: var(--plenoil-white) !important;
	--toastify-color-dark: var(--plenoil-black) !important;
	--toastify-color-info: var(--plenoil-blue) !important;
	--toastify-color-success: var(--plenoil-green) !important;
	--toastify-color-warning: var(--plenoil-orange) !important;
	--toastify-color-error: var(--plenoil-red) !important;
}

html, body {
	font-family: 'Red Hat Display'; 
	height: 100%;
	background: white;

	&.ios {
		padding-top: 30px;
	}
}

#root {
	height: 100%;
}

select {
	background: white;
}

input[type=number].no-arrows {
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	& {
	  -moz-appearance: textfield;
	}
}



.btn-plenoil {
	background: white;
	border-radius: 30px;
	font-size: 12px;
	padding: 10px 20px;
	font-weight: 600;
	border: 1px solid transparent;

	&:hover,
	&:active {
		opacity: 0.9;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&.btn-plenoil-white {
		background: white !important;
		color: var(--plenoil-black) !important;
	}

	&.btn-plenoil-orange {
		background: var(--plenoil-orange) !important;
		color: white !important;
	}

	&.btn-plenoil-orange3 {
		background: var(--plenoil-orange3) !important;
		color: white !important;
	}

	&.btn-plenoil-blue2 {
		background: var(--plenoil-blue2) !important;
		color: white !important;

		&.outline {
			background: transparent !important;
			border-color: var(--plenoil-blue2) !important;
			color: var(--plenoil-blue2) !important;
		}
	}
}

.box-plenoil {
	background: white;
	padding: 20px;
	border-radius: 20px;
	box-shadow: var(--plenoil-shadow);

	&.box-plenoil-padded {
		padding: 40px;
	}
}

.label-plenoil {
	font-weight: 600;
	font-size: 12px;
	padding-left: 20px;
	margin-bottom: 5px;
}

.input-plenoil {
	border: 1px solid var(--plenoil-black);
	padding: 8px 18px;
	border-radius: 30px;
	width: 100%;
	font-size: 13px;

	&.input-plenoil-blue2 {
		background: var(--plenoil-blue2);
		color: white;
	}
}

select.input-plenoil {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;

	background-image:
		linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position:
		calc(100% - 20px) calc(1em + 2px),
		calc(100% - 15px) calc(1em + 2px),
		calc(100% - .5em) .5em;
	background-size:
		5px 5px,
		5px 5px,
		1.5em 1.5em;
	background-repeat: no-repeat;
}


#btn-back {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: var(--plenoil-black);
	text-decoration: none;
	font-weight: 500;

	i {
		font-size: 13px;
		margin-right: 10px;
	}
}

h1.box-title {
	font-size: 17px;
	display: inline-block;
	margin-right: 10px;
}

select.box-select {
	margin-right: 10px;
	border: none;
	color: var(--plenoil-orange2);
	cursor: pointer;
	font-weight: 600;
	border-bottom: 2px solid var(--plenoil-orange2);
	padding-left: 0 !important;
	padding-right: 0;

	&:focus,
	&:active {
		outline: none;
	}

	option {
		color: var(--plenoil-black);
	}
}

table.box-table {
	width: 100%;

	thead {
		tr {
			th, td {
				text-transform: uppercase;
				font-weight: 600;
				font-size: 11px;
				padding-bottom: 5px;
			}
		}

		border-bottom: 1.5px solid var(--plenoil-brown);
	}	

	tbody {
		tr {
			td {
				font-weight: 600;
				font-size: 12px;
				padding: 5px 0px;
			}

			border-bottom: 1px solid var(--plenoil-brown);
		}
	}
}
